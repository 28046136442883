export const API_BASE_URL = "https://staging-api.myfarelanes.com/api/";


export const COPILOT_MONTHLY_API_KEY = "price_1QSLVNLgudBKYy4Gnx1yKpee";
export const COPILOT_YEARLY_API_KEY = "price_1QSLXQLgudBKYy4GlSRCMsP0";

export const SILVER_MONTHLY_API_KEY = "price_1OmjJeLgudBKYy4Gxlyos8zX";
export const SILVER_YEARLY_API_KEY = "price_1OmjqlLgudBKYy4G3IHS3Rzl";

export const PLATINUM_MONTHLY_API_KEY = "price_1PwJGuLgudBKYy4GV6alYB1c";
export const PLATINUM_YEARLY_API_KEY = "price_1PwJIlLgudBKYy4G1JvGKGgw";

export const GOLD_MONTHLY_API_KEY = "price_1OmjuJLgudBKYy4GNXA44eQN";
export const GOLD_YEARLY_API_KEY = "price_1OmjtPLgudBKYy4G1ktyqOCZ";

export const STRIPE_KEY = "pk_test_51NXRnlLgudBKYy4G62j8q4gr1zLOTkqsGZQg3w8TzLueTT0Dt0JjJkXekDXdqsntFWLXjv2QnyF39QYpsW3FSGqC00VqfUNp6g";

export const MONGO_CHARTS_AUTH_SIGN_KEY = "kjdflkdjflkdf87dkfnj8787jsdhs878";
export const MONGO_CHARTS_AUTH_SIGN_KEY_ALGORITHM = "HS256";
export const MONGO_CHARTS_AUTH_SIGN_KEY_USER = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_ISSUER = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_AUDIENCE = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_EXPIRY_TIME = "2h";
export const MONGO_CHARTS_BASE_URL = "https://charts.mongodb.com/charts-project-0-qpmdy";

