import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";

export const AdminDataSync = () => {
// get functions to build form with useForm() hook
    const { register, handleSubmit, reset } = useForm();

    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);
    const [state, setState] = useState({
        showResult: false,
        loading: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    setFarelanesUser({ firstName: response.data.auth0GivenName, lastName: response.data.auth0FamilyName, message: '',
                        numberOfDays: '31',
                        milesGreaterThan: '99',
                        lineHaulAmountGreaterThan: '100',
                        lineHaulAmountLessThan: '30000',
                        loading: false, })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    async function onSubmit(data) {
        // display form data on submit
        const token = await getAccessTokenSilently();
        setState({
            message: "",
            loading: true
        });

        let payload = {};
        UserService.doProductionDataIngestion(farelanesUser.numberOfDays, farelanesUser.milesGreaterThan, farelanesUser.lineHaulAmountLessThan, farelanesUser.lineHaulAmountGreaterThan, token).then(
            response => {
                setState({
                    content: response.data,
                    loading: false,
                    message: 'Success!'
                });
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );


        return false;
    }
    return (
        <div className="container-fluid">
            <div>
                <small className="">This operation deletes all production lane
                    data and creates a new collection using the below filters.</small><br/>
                <small className="">If you are a super admin, and unsure about the
                    below
                    operation - Please check with Andrew Cohen before running the job</small>
            </div>
            <div className="">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-sm">
                                <label>No.of Days.</label>
                                <input name="firstName" type="text" {...register('numberOfDays')} className="form-control AppFonts" />
                            </div>
                            <div className="form-group col-sm">
                                <label>(Miles Greater than)</label>
                                <input name="milesGreaterThan" type="text" {...register('milesGreaterThan')} className="form-control AppFonts" />
                            </div>
                            <div className="form-group col-sm">
                                <label>(LineHaul $ > Than)</label>
                                <input name="lineHaulAmountGreaterThan" type="text" {...register('lineHaulAmountGreaterThan')} className="form-control AppFonts" />
                            </div>
                            <div className="form-group col-sm">
                                <label>(LineHaul $  Than)</label>
                                <input name="lineHaulAmountLessThan" type="text" {...register('lineHaulAmountLessThan')} className="form-control AppFonts" />
                            </div>
                        </div>


                        <div className="form-group">
                            <button type="submit" className="button__settings-save">

                                {state.loading && (
                                    <span className="spinner-border spinner-border-sm admin-sync-subit-spiiner"></span>
                                )}
                                &nbsp;&nbsp;<span className="">RUN JOB</span></button>
                        </div>

                        {state.content && (
                            <div className="card">
                                <div className="card-header">
                                    <h5>Operation successful !</h5>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{state.content.recordsFound} <small>records
                                        found in General Lane Data for the
                                        last {state.numberOfDays} days from now</small></h5>
                                    <h5 className="card-title">{state.content.recordsFiltered} <small>records
                                        updated with the above filters</small></h5>
                                    <p className="card-text">Production lane data has been successfully
                                        recreated.</p>
                                    <hr/>
                                </div>
                            </div>
                        )}

                    </form>
                }
                {!farelanesUser &&
                    <div className="text-center p-3">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default withAuthenticationRequired(AdminDataSync, {
    onRedirecting: () => <Loading />,
});
