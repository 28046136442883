import {API_BASE_URL} from "../common/ApiUrl";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import {token} from "morgan";


class LaneService {

    async getTopLanes(token) {
        return axios.get(API_BASE_URL + 'top_routes', {headers: {Authorization: `Bearer ${token}`}});
    }

    getLaneStatistics(pick_city, pick_state, drop_city, drop_state, equipment, pick_city_radius, drop_city_radius, days, token) {
        return axios.get(API_BASE_URL + `lane-statistics?pick_city=${pick_city}&drop_city=${drop_city}&pick_state=${pick_state}&drop_state=${drop_state}&equipment=${equipment}&pick_city_radius=${pick_city_radius}&drop_city_radius=${drop_city_radius}&days=${days}`, {headers: {Authorization: `Bearer ${token}`}});
    }


    syncDashBoard(data, token) {
        return axios.post(API_BASE_URL + 'dashboard',   data
        ,{headers: {Authorization: `Bearer ${token}`}});
    }

    deleteDashBoardItem(sub, item, token) {
        return axios.put(API_BASE_URL + 'dashboard-delete',   {sub: sub.sub, item: item}, {headers: {Authorization: `Bearer ${token}`}});
    }

    shuffleDashBoardItem(sub, item, op, token) {
        return axios.put(API_BASE_URL + 'dashboard-shuffle',   {sub: sub.sub, item: item, op: op}, {headers: {Authorization: `Bearer ${token}`}});
    }

    getCityOptions(query, token) {
        return axios.get(API_BASE_URL + `city_search?q=${query}`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    getUserRateSearchOptions(token) {
        return axios.get(API_BASE_URL + `rate_search`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    getCityOptionsTwo(query, token) {
        return axios.get(API_BASE_URL + `city_top_routes`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    getCityOptionsThree(query, token,city, stateName, radiusValue) {
        return axios.get(API_BASE_URL + `city_top_routess?pick_city=${city}&pick_state=${stateName}&pick_city_radius=${radiusValue}`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    loadDashBoard(token) {
        return axios.get(API_BASE_URL + `dashboard-items`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }

    loadDashBoardFavorites(token) {
        return axios.get(API_BASE_URL + `dashboard-fav-items`, {headers: {Authorization: `Bearer ${token}`}}).then(res => res.data);
    }
}

export default new LaneService();



